import React, { useState, useRef, useEffect, useContext } from "react";
import { LuckyWheel } from "@lucky-canvas/react";
import { Button, Flex } from "antd";
import axios from "axios";
import { useParams } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

export default function Lucky() {
  const { cid } = useParams("unset");
  const { uid } = useParams("unset");
  const { storeName } = useParams("");
  const [luckyResult, setLuckyResult] = useState({});

  const [blocks] = useState([{ padding: "10px", background: "#fe7011" }]);
  const [prizes, setPrizes] = useState([]);
  const [buttons] = useState([
    { radius: "40%", background: "#f43713" },
    { radius: "35%", background: "#f8481c" },
    {
      radius: "30%",
      background: "#ffb11c",
      pointer: true,
      fonts: [{ text: "开始", top: "-10px", fontColor: "white" }],
    },
  ]);
  const myLucky = useRef();
  const maxWidth = 430;
  const width = Math.min(window.innerWidth, maxWidth);

  const colors = ["#fcbe0e", "#fd5624"];
  const getLucky = () => {
    axios
      .post(`/lucky/getlucky`, {
        uid: uid,
        clientId: cid,
      })
      .then((response) => {
        setLuckyResult(response.data);
      });
  };

  const getRanges = () => {
    axios
      .post(`/lucky/getraffles`, {
        storeId: cid,
      })
      .then((response) => {
        var values = response.data.map((d, index) => {
          return {
            range: d.range,
            background: colors[index % 2],
            fonts: [
              {
                text: d.value ? `${d.value}元` : "谢谢参与",
                top: 10,
                fontColor: "white",
              },
            ],
            value: d.value,
          };
        });
        setPrizes(values);
        console.log(values);
      });
  };

  useEffect(() => {
    if (!cid || !uid) return;
    //setCurrentGame(id);

    getRanges();
    getLucky();

    return () => {
      //setCurrentGame(null);
    };
  }, [cid, uid]);

  return (
    <div style={{ textAlign: "center" }}>
      <Flex
        gap="middle"
        vertical
        justify="space-between"
        style={{
          height: "100%",
          backgroundImage: "url(/imgs/luck_background.png)",
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
          backgroundColor: "#e13323",
        }}
      >
        <div
          style={{
            flex: 0.5,
          }}
        >
          <div
            style={{
              textAlign: "center",
              paddingTop: 50,
              fontWeight: "bold",
              fontSize: 50,
              color: "white",
            }}
            onClick={() => {
              window["uni"].navigateTo({
                url: "/pages/index/storeIntro/storeIntro",
              });
            }}
          >
            &nbsp;{storeName}&nbsp;
          </div>
        </div>
        <div
          style={{
            paddingTop: `${(50 / 430) * width}%`,
            display: "flex",
            flexFlow: "row",
          }}
        >
          <div style={{ flex: 0.92 }}></div>
          <LuckyWheel
            style={{ flex: 1 }}
            ref={myLucky}
            width={width - (100 / 430) * width}
            height={width - (100 / 430) * width}
            blocks={blocks}
            prizes={prizes}
            buttons={buttons}
            onStart={() => {
              if (luckyResult.amount <= 0) {
                toast("抽奖券用完啦，参与互动可以获得抽奖券哦");
                return;
              }

              // 点击抽奖按钮会触发star回调
              myLucky.current.play();

              //从服务器接受抽奖结果
              axios
                .post("/lucky/getraffleindex", {
                  storeId: cid,
                })
                .then((res) => {
                  setTimeout(() => {
                    myLucky.current.stop(res.data);
                  }, 2500);
                });
            }}
            onEnd={(prize) => {
              // 抽奖结束会触发end回调
              if (prize.value && Number(prize.value) > 0)
                toast("恭喜你抽到 " + prize.fonts[0].text);
              else
                toast(
                  "感谢您的参与，本次没有中奖，参加首页活动可以获得更多抽奖券哦"
                );
              axios
                .post("/lucky/raffle", {
                  uid: uid,
                  clientId: cid,
                  value: prize.value,
                })
                .then((res) => {
                  getLucky();
                });
            }}
          />
          <div style={{ flex: 1.08 }}></div>
        </div>
        <div
          style={{
            flex: 0.5,
            paddingLeft: 16,
            paddingRight: 16,
            pointerEvents: "none",
          }}
        >
          <Flex gap="middle" vertical style={{ height: "100%" }}>
            <div style={{ flex: 1 }}></div>
            <Button ghost size="large">
              <span style={{ fontWeight: "bold" }}>
                当前抽奖券：{luckyResult.amount || "0"} 张
              </span>
            </Button>
          </Flex>
        </div>
      </Flex>
      <ToastContainer
        position="top-center"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
    </div>
  );
}
