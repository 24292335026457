import React, { useContext, useEffect, useState } from "react";
import {
  Avatar,
  Badge,
  Button,
  Card,
  Carousel,
  Col,
  Divider,
  Flex,
  Row,
  Space,
} from "antd";
import "./Home.css";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { Container } from "reactstrap";
import { ClientInfo, UserInfo } from "./Layout";

const contentStyle = {
  height: "160px",
  lineHeight: "160px",
  textAlign: "center",
};

export default function Home() {
  const navigate = useNavigate();
  const [games, setGames] = useState();
  const { currentClient } = useContext(ClientInfo);
  const { currentUser } = useContext(UserInfo);

  const validate = () => currentClient && currentUser;

  useEffect(() => {
    axios.get("/game").then((res) => {
      setGames(res.data);
    });
  }, []);

  const gameNameStyle = {
    position: "absolute",
    marginTop: -48,
    color: "white",
    width: "100%",
    height: 48,
    paddingTop: 12,
    paddingLeft: 8,
    paddingRight: 8,
    background: "linear-gradient(#00000000, #00000066)",
    textShadow: " 0 0 4px darkGray",
    textAlign: "left",
    lineHeight: 1,
    borderRadius: 8,
    overflow: "hidden",
  };
  const gameDesStyle = { fontSize: ".75rem", color: "gray" };

  const navigationColStyle = {
    textAlign: "center",
  };
  const navigationButtonStyle = {
    width: "18vw",
    height: "18vw",
  };

  const historySize = 60;

  return (
    <>
      <Container style={{ paddingBottom: 88 }}>
        <Carousel autoplay>
          <div>
            <h3 style={contentStyle}>
              <div className="top-box">
                <img src="/imgs/banner_1.webp" alt="" />
              </div>
            </h3>
          </div>
          <div>
            <h3 style={contentStyle}>
              <div className="top-box">2</div>
            </h3>
          </div>
          <div>
            <h3 style={contentStyle}>
              <div className="top-box">3</div>
            </h3>
          </div>
          <div>
            <h3 style={contentStyle}>
              <div className="top-box">4</div>
            </h3>
          </div>
        </Carousel>
        <Row
          style={{
            marginTop: "0.5rem",
            marginBottom: "0.75rem",
            marginLeft: -16,
            marginRight: -16,
            background: "#fafafa",
            paddingTop: 8,
            paddingBottom: 16,
          }}
        >
          <Col span={6} style={navigationColStyle}>
            <Button
              className="bg-light"
              type="secondary"
              style={navigationButtonStyle}
              shape="circle"
              icon={<i className="iconfont icon-qiandao nav"></i>}
              onClick={() => {
                navigate("/sign");
              }}
            />
            <div className="nav-btn-title">签到赚积分</div>
          </Col>
          <Col span={6} style={navigationColStyle}>
            <Button
              className="bg-light"
              type="secondary"
              style={navigationButtonStyle}
              shape="circle"
              icon={<i className="iconfont icon-yaoqing nav"></i>}
              onClick={() => {
                navigate("/invite");
              }}
            />
            <div className="nav-btn-title">邀请有礼</div>
          </Col>
          <Col span={6} style={navigationColStyle}>
            <Button
              className="bg-light"
              type="secondary"
              style={navigationButtonStyle}
              shape="circle"
              icon={
                <i
                  className="iconfont icon-huodong nav"
                  style={{ fontSize: "9vw", marginTop: "4.5vw" }}
                ></i>
              }
            />
            <div className="nav-btn-title">活动中心</div>
          </Col>
          <Col span={6} style={navigationColStyle}>
            <Button
              className="bg-light"
              type="secondary"
              style={navigationButtonStyle}
              shape="circle"
              icon={
                <i
                  className="iconfont icon-qianbao nav"
                  style={{ fontSize: "10vw", marginTop: "5vw" }}
                ></i>
              }
              onClick={() => {
                navigate("/charge");
              }}
            />
            <div className="nav-btn-title">账户充值</div>
          </Col>
        </Row>
        <div className="main-activity">
          <img src="/imgs/main-activity.webp" alt="" className="w-100" />
        </div>
        <Row gutter={16}>
          <Col span={24} style={{ padding: 8, paddingBottom: 8 }}>
            <Flex justify="space-between" align="center">
              <div>
                <strong>最近参与</strong>
                <br />
                <span style={gameDesStyle}>查看全部 &gt;</span>
              </div>
              <Space>
                <Badge>
                  <Avatar shape="square" size={historySize} />
                </Badge>
                <Badge>
                  <Avatar shape="square" size={historySize} />
                </Badge>
                <Badge>
                  <Avatar shape="square" size={historySize} />
                </Badge>
                <Badge>
                  <Avatar shape="square" size={historySize} />
                </Badge>
              </Space>
            </Flex>
          </Col>
        </Row>
        <Divider style={{ marginTop: "0.5rem", marginBottom: ".75rem" }}>
          <i className="iconfont icon-icon_hot"></i>&nbsp;&nbsp;热门互动
        </Divider>
        <Row gutter={16}>
          {validate &&
            games?.map((g, index) => (
              <Col span={12} key={`g_${index}`}>
                <Card
                  hoverable
                  className="g-card"
                  onClick={() => {
                    //let url = "/gs/001/";
                    let url = `/${currentClient?.id}/${currentUser?.id}/playgame/${g.id}`;
                    //if (window.__wxjs_environment === "miniprogram") {
                    //wx.miniProgram.navigateTo({ url });
                    //alert("在微信中");
                    //} else {
                    //}
                    navigate(url);
                  }}
                >
                  <img alt="" src={`/gs/${g.id}/cover.png`} className="w-100" />
                  <div align="center" style={gameNameStyle}>
                    <span>{g.name}</span>
                    <br />
                    <span
                      style={{
                        fontSize: ".6rem",
                        color: "#ddd",
                      }}
                    >
                      {g.desc}
                    </span>
                  </div>
                </Card>
              </Col>
            ))}
        </Row>
      </Container>
    </>
  );
}
