import Home from "./components/Home";
import Game from "./components/Game";
import Sign from "./components/Sign";
import Invite from "./components/Invite";
import Charge from "./components/Charge";
import Lucky from "./components/Lucky";

const AppRoutes = [
  {
    index: true,
    element: <Home />,
  },
  {
    path: "/sign",
    element: <Sign />,
  },
  {
    path: "/invite",
    element: <Invite />,
  },
  {
    path: "/charge",
    element: <Charge />,
  },
  {
    path: "/:cid/:uid",
    element: <Game />,
  },
  {
    path: "/:cid/:uid/playgame/:id",
    element: <Game />,
  },
  {
    path: "/:cid/:uid/:storeName/lucky",
    element: <Lucky />,
  },
];

export default AppRoutes;
