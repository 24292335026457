export default function Invite() {
  const imgStyle = {
    width: "100%",
  };

  return (
    <>
      <img src="/imgs/invite_top.png" alt="" style={imgStyle} />
      <img
        src="/imgs/invite_btn.png"
        alt=""
        style={imgStyle}
        onClick={() => {
          alert("邀请");
        }}
      />
      <img src="/imgs/invite_rule.jpg" alt="" style={imgStyle} />
    </>
  );
}
