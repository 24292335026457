import { useParams } from "react-router-dom";
import { useState } from "react";
import Iframe from "react-iframe";
import Landing from "./Landing";
import axios from "axios";

export default function Game() {
  const { cid } = useParams("unset");
  const { uid } = useParams("unset");
  const { id } = useParams();

  const [isLoaded, setIsLoaded] = useState(false);

  const OnInit = () => {
    console.log("Init");
    setTimeout(() => {
      setIsLoaded(true);
    }, 2000);
  };

  const OnStart = () => {
    console.log("Started");
  };

  const OnLevelPass = (level) => {
    console.log("level pass : " + level);
    var data = {
      uid: uid,
      clientId: cid,
      gameId: id,
    };
    console.log(data);
    axios.post("/lucky/index", data).then((response) => {
      console.log(response);
    });
  };

  const OnIframeLoaded = () => {
    var gameManager =
      document.querySelector("iframe").contentWindow.InteractiveGameManager;

    gameManager.onInit = OnInit;
    gameManager.onStart = OnStart;
    gameManager.onLevelPass = OnLevelPass;
  };

  return (
    // <>
    //   <div
    //     style={{
    //       height: "100%",
    //     }}
    //   >
    //     <Iframe
    //       id="gameFrame"
    //       width="100%"
    //       height="100%"
    //       title={id}
    //       src={`/gs/${id}/index.html?code=`}
    //       style={{
    //         position: "absolute",
    //       }}
    //       onLoad={OnIframeLoaded}
    //     ></Iframe>
    //   </div>

    //   {!isLoaded && (
    //     <div
    //       style={{
    //         position: "absolute",
    //         width: "100%",
    //         zIndex: 10000,
    //         top: 0,
    //         bottom: 0,
    //         left: 0,
    //         right: 0,
    //       }}
    //     >
    //       <Landing />
    //     </div>
    //   )}
    // </>
    <div
      style={{
        width: "100%",
        height: "100%",
        background: "#e7222b",
      }}
    >
      <div
        style={{
          width: "100%",
          height: "100%",
          background: "url(imgs/activity.jpg)",
          backgroundSize: "contain",
          backgroundRepeat: "no-repeat",
        }}
      ></div>
    </div>
  );
}
