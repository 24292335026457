import Card from "antd/es/card/Card";
import { ClientInfo, UserInfo } from "./Layout";
import { useContext, useState } from "react";
import "./Charge.css";
import { Button, Col, Row } from "antd";
import { CheckOutlined, PayCircleOutlined } from "@ant-design/icons";
import axios from "axios";

export default function Charge({ goBack }) {
  const { currentClient } = useContext(ClientInfo);
  const { currentUser } = useContext(UserInfo);

  const options = [
    {
      label: "100",
      desc: "首充 送20元",
      key: 100,
    },
    {
      label: "200",
      desc: "首充 送50元",
      key: 200,
    },
    {
      label: "500",
      desc: "首充 送150元",
      key: 500,
    },
    {
      label: "800",
      desc: "首充 送280元",
      key: 800,
    },
    {
      label: "1000",
      desc: "首充 送400元",
      key: 1000,
    },
    {
      label: "2000",
      desc: "首充 送900元",
      key: 2000,
    },
  ];
  const [value, setValue] = useState(options[0].key);

  return (
    <div className="charge">
      <Card className="client-card" title={currentClient.name}>
        <div className="logo">
          <i className="iconfont icon-shangpuxiezilou" />
        </div>
      </Card>
      <div className="sep">
        {currentUser.name}，您正在为 {currentClient.name} 充值
      </div>
      <Card
        className="charge-card"
        title="充值金额"
        extra={<span className="text-muted">注：首充奖励仅可享受一次</span>}
      >
        <Row gutter={[16, 16]}>
          {options &&
            options.map((v) => (
              <Col span={12} key={v.key}>
                <div
                  className={`charge-radio ${value === v.key && "selected"}`}
                  onClick={() => setValue(v.key)}
                >
                  <span className="charge-label">{v.key}</span>
                  <span className="charge-unity"> 元</span>
                  {value === v.key && (
                    <>
                      <div className="charge-selected"></div>
                      <div className="charge-selected-icon">
                        <CheckOutlined />
                      </div>
                    </>
                  )}
                  <div className="charge-desc">{v.desc}</div>
                </div>
              </Col>
            ))}
        </Row>
      </Card>
      <div className="sign-button-container">
        <Button
          type="text"
          block
          size="large"
          className="sign-button"
          icon={<PayCircleOutlined />}
          onClick={() => {
            window.wx.miniProgram.navigateTo({ url: "/pages/index/index" });
          }}
        >
          立即充值
        </Button>
      </div>
      <div className="sign-button-container">
        <Button
          block
          size="large"
          className="sign-button"
          onClick={() => {
            goBack();
          }}
        >
          <span style={{ color: "black" }}>返回个人中心</span>
        </Button>
      </div>

      <div
        className="w-100"
        style={{ paddingLeft: 16, paddingRight: 16, marginTop: 32 }}
      >
        <img src="/imgs/charge-activity.jpg" alt="" className="w-100" />
      </div>
    </div>
  );
}
