import { Segmented } from "antd";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

export function Layout({ children }) {
  const { cid } = useParams("unset");
  const { uid } = useParams("unset");
  const [currentClient, setCurrentClient] = useState();
  const [currentUser, setCurrentUser] = useState();
  const [currentGame, setCurrentGame] = useState();

  useEffect(() => {
    if (cid !== "unset") setCurrentClient({ cid });
  }, [cid]);

  useEffect(() => {
    if (uid !== "unset") setCurrentUser({ uid });
  }, [uid]);

  return (
    <ClientInfo.Provider value={{ currentClient }}>
      <UserInfo.Provider value={{ currentUser }}>
        <GameInfo.Provider value={{ currentGame, setCurrentGame }}>
          {children}
        </GameInfo.Provider>
      </UserInfo.Provider>
    </ClientInfo.Provider>
  );
}

export const ClientInfo = React.createContext();
export const UserInfo = React.createContext();
export const GameInfo = React.createContext();
