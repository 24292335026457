import { useState } from "react";
import Calendar from "react-calendar";
import "react-calendar/dist/Calendar.css";
import "./Sign.css";
import moment from "moment";
import { Button, Card, Divider } from "antd";
import { EditOutlined } from "@ant-design/icons";

export default function Sign() {
  const [date, setDate] = useState(new Date());

  return (
    <>
      <Card
        className="calendar-card"
        title={
          <div
            className="text-white w-100"
            style={{
              display: "flex",
              fontSize: ".875rem",
              justifyContent: "space-between",
              fontWeight: "normal",
            }}
          >
            <span className="text-white">每日签到送 1 积分</span>
            <span>当前积分：100</span>
          </div>
        }
      >
        <div className="w-100 text-center py-3 title">
          {moment().format("YYYY年MM月")}
        </div>
        <div className="sign-container bg-white">
          <Calendar
            onChange={setDate}
            value={date}
            className="w-100"
            showNavigation={false}
            formatDay={(_, date) => moment(date).format("DD")}
          />
          <Divider />
          <div className="sign-button-container">
            <Button
              type="text"
              block
              size="large"
              className="sign-button"
              icon={<EditOutlined />}
            >
              立即签到
            </Button>
          </div>
        </div>
      </Card>

      <div className="sign-activity">
        <img src="/imgs/sign-banner.png" alt="" />
      </div>
    </>
  );
}
